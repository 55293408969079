@import "../variables";

.wizard {
    .MuiStepIcon-root {
        &.MuiStepIcon-active {
            color: $color-primary;
        }
        &.MuiStepIcon-completed {
            color: $color-success;
        }
    }
}

.wizard__dt {
    .wizard-stepper {
        background-color: $color-card__dt;
    }

    .MuiStepLabel-label {
        &.MuiStepLabel-active,
        &.MuiStepLabel-alternativeLabel {
            color: $color-primary__dt;
        }
    }
    .MuiStepIcon-root {
        &.MuiStepIcon-root {
            color: $color-secondary;
        }
        &.MuiStepIcon-active {
            color: rgba(0, 0, 0, 0.87);
        }
        &.MuiStepIcon-completed {
            color: $color-success__dt;
        }
    }
}
