
// Removing scrollbars
.drag-and-drop-manger * {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
    }

}
