@import "../variables";

.expand-row {
    max-width: 100%;
    position: relative;

    .message {
        font-weight: bold;
        margin-left: 15px;
    }

    .label {
        margin-right: 15px;
    }

    .progress-bar {
        box-sizing: border-box;
        max-width: 600px;
        display: inline-block;
        border-radius: 0.25rem;
    }

    .error {
        position: relative;
        width: 100%;
        overflow: hidden;
        display: inline-block;

        .code-mirror {
            width: 100%;
            border: 1px solid $color-secondary;
        }
    }
}

.empty-table {
    .message {
        font-size: 16px;
    }

    .item {
        font-size: 100px;
        display: block;
        position: relative;
        border: none;
        text-align: center;

        .content {
            padding: 15px;
            transition: 0.3s ease;

            & i {
                transition: 0.3s ease;
                cursor: pointer;
            }

            a {
                width: 100%;
                height: auto;
            }

            p {
                font-size: 18px;
            }
        }
    }
}

@media (max-width: $sm) {
    .table-card {
        margin-left: -30px;
        margin-right: -30px;
    }
}
