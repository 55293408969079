.icon-wrapper {
    display: flex;
    flex-direction: column;
    background: transparent !important;
    border: none !important;
    outline: none !important;
    cursor: pointer;
    align-items: center;

    .icon-text {
        margin-top: 15px;
    }

    .icon {
        height: auto;
        transition: 0.3s;
        width: 200px;


        &:hover {
            opacity: 0.7;
            transition: 0.3s;
        }
    }
}
