@import "../variables";

.prim-button {
    color: #20a8d8;

    &:hover {
        color: #2f353a;
    }
}

.color-success {
    color: $success;
}

.color-error {
    color: $danger;
}

.icon-check {
    color: $success;
}

.icon-warning {
    color: $color-warning;
}

.icon-failed {
    color: $danger;
}

.z-index-1000 {
    z-index: 1000;
}

.max-width-80 {
    max-width: 80% !important;
}

.max-width-60 {
    max-width: 60% !important;
}

.primary-color {
    color: $color-primary;
}

.flex-container {
    display: flex;
    flex-direction: column;
}

.droppable-container {
    resize: vertical;
    border: 1px solid grey;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: grey 0px 0px 4px inset;
    min-height: 25vw;
    height: 25vw;
}

.droppable-container-first-child {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.droppable-container-second-child {
    overflow: scroll;
    height: 100%;
    width: 100%;
    padding: 10px;
}

.drop-indicator {
    &:hover {
        border: 1px dashed #2982cc;
    }
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.drag-drop-row {
    border: 1px solid blue;
    margin: auto;
    display: flex;
    width: max-content;
}

.drag-drop-column {
    border: 1px solid red;
    padding: 2px;
    margin: 0px;
}

.dark-select-input {
    div {
        background-color: #2f303a;
        color: #e1e1e1;
        border-color: rgba(255, 255, 255, 0.15);
    }
}

.height-width-100 {
    height: 100%;
    width: 100%;
}

.dropzone-vertical {
    height: 5vh;
    width: 100%;
}

.dropzone-vertical-hover {
    &:hover {
        height: 10vh !important;
        transition: height 0.25s ease-in;
    }
}

.dropzone-horizontal {
    height: 100%;
    width: 4vw;
}

.dropzone-horizontal-hover {
    &:hover {
        width: 6vw !important;
        transition: width 0.25s ease-in;
    }
}

.dropzone-row {
    &:hover {
        height: 5vw !important;
        transition: height 0.25s ease-in;
    }
}

.dropzone-column {
    &:hover {
        width: 5vw !important;
        transition: width 0.25s ease-in;
    }
}

.droppable-select {
    height: 100%;
    width: 100%;
    padding: 0.5rem 1.25rem;
    z-index: 9999;
}

.display-flex {
    display: flex;
}
.z-index-0 {
    .selection {
        z-index: unset !important;
    }
}
.fullScreenModal {
    > div {
        > div {
            > div {
                margin: 0 !important;
                width: 100vw !important;
                max-width: 100vw !important;
                > div {
                    > div {
                        > div {
                            > div {
                                width: 100%;
                                margin: 0;
                                max-width: 100vw;
                            }
                        }
                    }
                }
            }
        }
    }
    .droppable-container {
        height: 65vh !important;
    }
}

@media (max-width: $sm) {
    .res-sm-margin-15 {
        margin-right: -15px;
        margin-left: -15px;
    }

    .res-sm-margin-30 {
        margin-right: -30px;
        margin-left: -30px;
    }
}
