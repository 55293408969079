@import "../variables";

.custom-dropzone {
    .dzu-dropzone {

        overflow: hidden;
        border-style: dashed;

        .preview {
            display: block;
            width: 100%;
            padding: 15px;

        }

        .dzu-inputLabel {
            margin: 0;
        }
    }
}


@media (max-width: $sm) {
    .custom-dropzone .dzu-dropzone .dzu-inputLabel {
        margin: 15px;
    }
}
