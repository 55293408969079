@import "../variables";

.confirm-dialog {
    .confirm-card {
        width: 300px;
        height: auto;
        padding: 15px;
        display: block;

        .confirm-button {
            width: 50px;
            height: auto;
            display: inline-block;
            margin-right: 15px;
        }
    }
}

.popup-content {
    border-radius: .25rem;
}

@media (max-width: $sm) {
    .popup-content {
        margin-right: 15px !important;
        margin-left: 15px !important;
        width: 100% !important;
    }
}
