@import "../variables";

.cssEditor {
    .editor {
        position: relative;
        height: 100%;
        overflow-x: auto;

        .editor__preview {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            .editor__border {
                border: 2px solid $color-info;
                width: fit-content;
                float: right;
            }
        }

        .editor__page {
            border: 2px solid $color-primary;
            background-color: #fff;
            position: relative;
            box-sizing: border-box;

            .page__content {
                border: 2px dotted $color-danger;
                height: 100%;

                .content__row {
                    height: 100%;
                    margin: 0;
                    overflow: hidden;

                    .content__cell {
                        padding: 5px;
                        opacity: 0.9;

                        .content {
                            opacity: 1;
                            background-color: $color-light;
                            .content__image {
                                margin-bottom: 5px;
                                img {
                                    width: 100%;
                                }
                            }
                            .content__price {
                                margin-bottom: 5px;
                                font-size: 10pt;
                                font-weight: bold;
                            }
                            .content__description {
                                column-count: 2;
                                hyphens: auto;
                                text-align: justify;
                                column-gap: 8px;
                            }
                        }
                    }
                }
            }
        }

        .editor__pageNumber {
            position: relative;

            .pageNumber__page {
                display: block;
                right: 0;
                border: 2px solid $color-primary;
                background-color: #fff;
                position: relative;

                .page__pageNumber {
                    width: 100%;
                    height: 100%;
                    z-index: 10;

                    .page__content {
                        display: inline-block;
                        position: absolute;
                        width: fit-content;
                    }
                }
            }
            .pageNumber__bigView {
                position: relative;
                height: 100%;
                width: 100%;
                .bigView__content {
                    border: 2px dotted $color-danger;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    box-sizing: content-box;
                    width: fit-content;
                    height: fit-content;
                    display: inline-flex;
                    text-align: right;

                    .bigView__number {
                        display: inline;
                        font-size: 14pt;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }
    }

    .cssEditor__inputMask {
        .inputMask__group {
            padding-bottom: 15px;
            margin-bottom: 15px;
            padding-left: 15px;
            padding-right: 15px;
            margin-left: -15px;
            margin-right: -15px;
            border-bottom: 1px solid $color-secondary;

            .group__title {
                display: block;
                font-size: 1.1rem;
                font-weight: bold;
                margin-bottom: 15px;
            }
        }

        .mask__container {
            &:last-child {
                .inputMask__group {
                    padding-bottom: 15px;
                    margin-bottom: 15px;
                    padding-left: 15px;
                    padding-right: 15px;
                    margin-left: -15px;
                    margin-right: -15px;
                }
            }
        }
    }
}
