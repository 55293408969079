// If you want to override variables do it here
@import './variables.scss';

// If you want to add something do it here
@import 'custom';

// ie fixes
@import 'ie-fix';

body {
    margin: 0;
    padding: 0;
}
