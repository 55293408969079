@import "../variables";

.logo.navbar-brand {
    width: 150px;
    height: 50px;

    img {
        height: 100%;
        width: 50px;
    }
}

.navbar-brand-minimized {
    margin-left: 5px
}


@media (max-width: $sm) {
    .logo.navbar-brand {
        width: 60px;
        height: 50px;

        img {
            height: 100%;
            width: 30px;
        }
    }
}
