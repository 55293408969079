.aut-preview-images {
    img {
        width: 100%;
    }
}
.front-back-page {
    cursor: pointer;
    display: inline-block;
}

.preview-container {
    display: flex;
    align-items: flex-start;
    height: 100%;
}
.preview-item {
    position: sticky;
    top: 13%;
}
